import { isLuckyUser } from '../../utils/url';
import { type Config, type LanguagesConfig } from '../../interface/config';
import { type RendererModalParams } from '../../interface/modal';

const createSmokeTestHTML = (locale: string, languagesConfig: LanguagesConfig): string => {
  if (isLuckyUser()) {
    const { smokeTestURL, smokeTest } = languagesConfig;

    return `
            <div class='gdpr-modal__smoke-test'>
                <span class='gdpr-modal__smoke-test-name'>
                    <a id='gdpr-modal__hide-ad' target='_blank' href="${smokeTestURL[locale]}">
                        ${smokeTest[locale]}
                    </a>
                    <div id='gdpr-modal__eye-icon' class='gdpr-modal__eye-icon'></div>
                </span>
            </div>
        `;
  }

  return '';
};

const createHideAdsHTML = (locale: string, languagesConfig: LanguagesConfig, isHidingAdsAllowed: boolean): string => {
  if (isHidingAdsAllowed) {
    const { hideAd } = languagesConfig;

    return `
            <div class='gdpr-modal__hide-ads' role="button" data-testid="modal-hide-ads">
                <span class='gdpr-modal__hide-ads-label'>
                    ${hideAd[locale]}
                </span>
                <span class='gdpr-modal__eye-icon' title="${hideAd[locale]}"></span>
            </div>
        `;
  }

  return '';
};

const createHeaderLogoHTML = (): string => {
  return '<a class=\'gdpr-modal__header-logo\' href="https://schibsted.com" target="_blank" rel="noopener" title="schibsted"></a>';
};

const createHeaderTitleHTML = (headerSiteText: string): string => {
  return `<div class='gdpr-modal__header-site-name'>${headerSiteText}</div>`;
};

const createHeaderOrgHTML = (headerOrgText: string): string => {
  return `<div class='gdpr-modal__header-org-name'>${headerOrgText}</div>`;
};

const createLinksSettingsHTML = (locale: string, languagesConfig: LanguagesConfig, linksSettings: string): string => {
  return `
        <a href='${linksSettings}' target='_blank' rel='noopener' id='gdpr-modal__link-settings' class='gdpr-modal__link'>
            <span>
                ${languagesConfig.manageheading[locale]}
            </span>
        </a>`;
};

const createLinksReadMoreHTML = (locale: string, languagesConfig: LanguagesConfig, linksReadmore: string): string => {
  return `
        <div class='gdpr-modal__links-read-more'>
            <a href='${linksReadmore}' target='_blank' rel='noopener' id='gdpr-modal__link-read-more' class='gdpr-modal__link'>
                <span>${languagesConfig.readmoreschibstedlinktext[locale]}</span>
            </a>
        </div>
    `;
};

const createModalHeaderHTML = (
  locale: string,
  languagesConfig: LanguagesConfig,
  isPolarisSite: boolean | Config
): string => {
  const headerSiteText: string = languagesConfig.title[locale];
  const headerOrgText: string = isPolarisSite ? languagesConfig.family.POLARIS : languagesConfig.family[locale];

  const logoHeaderHTML = createHeaderLogoHTML();
  const titleHeaderHTML = createHeaderTitleHTML(headerSiteText);
  const orgHeaderHTML = createHeaderOrgHTML(headerOrgText);

  return `
        <div id='gdpr-modal__label' class='gdpr-modal__header'>
            ${logoHeaderHTML}
            ${titleHeaderHTML}
            ${orgHeaderHTML}
        </div>
    `;
};

const createModalAdvertiserHTML = (locale: string, languagesConfig: LanguagesConfig): string => {
  const advertiserHeader = languagesConfig.advertiserHeader[locale];
  const creativeId = languagesConfig.annonseId[locale];
  const advertiserName = languagesConfig.advertiserName[locale];

  return `
        <div class='gdpr-modal__advertiser' data-testid="gdpr-modal-advertiser">
            ${
              advertiserHeader
                ? `<p class='gdpr-modal__advertiser-header'>
                    ${advertiserHeader}
                  </p>`
                : ''
            }
            <p class="gdpr-modal__advertiser-creativeId">
                <span>${creativeId}:</span>
                <span id="gdprAdTransparencyModal__creativeId"></span>
            </p>
            ${
              advertiserName
                ? `<p class="gdpr-modal__advertiser-advertiser">
                    <span>${advertiserName}</span>
                    <span id="gdpr-modal__advertiser-name"></span>
                  </p>`
                : ''
            }
        </div>
    `;
};

const createModalSchibstedSectionHTML = (
  locale: string,
  languagesConfig: LanguagesConfig,
  linksReadmore: string
): string => {
  const linksReadmoreHTML = createLinksReadMoreHTML(locale, languagesConfig, linksReadmore);

  return `
        <div class='gdpr-modal__infobox-section'>
            <p class='gdpr-modal__infobox-header'>
                ${languagesConfig.aboutschibsted[locale]}
            </p>         
            ${linksReadmoreHTML}
        </div>
    `;
};

const createModalInfoSectionHTML = (
  locale: string,
  languagesConfig: LanguagesConfig,
  linksSettings: string,
  isMarketplaceSite: boolean | Config,
  isPolarisSite: boolean | Config
): string => {
  const linksSettingsHTML = createLinksSettingsHTML(locale, languagesConfig, linksSettings);

  const infoboxHeaderText = isMarketplaceSite
    ? `${languagesConfig.whymarketplace[locale]}`
    : `${languagesConfig.why[locale]}`;
  const explanationHeaderText = isPolarisSite
    ? languagesConfig.whyexplained.POLARIS
    : languagesConfig.whyexplained[locale];

  return `
        <div class='gdpr-modal__infobox-section'>
            <p class='gdpr-modal__infobox-header' data-testid="gdpr-modal-header">${infoboxHeaderText}</p>
            <p class='gdpr-modal__infobox-text' data-testid="gdpr-modal-text">${explanationHeaderText}</p>
            ${linksSettingsHTML}
        </div>
    `;
};

const createCloseButtonHTML = (locale: string, languagesConfig: LanguagesConfig): string => {
  return `<div id='gdpr-modal__close-button' data-testid="gdpr-modal-close" class='gdpr-modal__close-button' aria-label="${languagesConfig.close[locale]}" role='button'>${languagesConfig.close[locale]}</div>`;
};

const createModalButtonsSectionHTML = (
  locale: string,
  languagesConfig: LanguagesConfig,
  isHidingAdsAllowed: boolean
): string => {
  const closeButtonHTML = createCloseButtonHTML(locale, languagesConfig);
  const modalHideAdsHTML = createHideAdsHTML(locale, languagesConfig, isHidingAdsAllowed);

  return `
    <div class='gdpr-modal__buttons-section'>
      ${closeButtonHTML}
      ${modalHideAdsHTML}
    </div>`;
};

const createModalHTML = ({
  siteConfig,
  languagesConfig,
  locale,
  linksSettings,
  linksReadmore
}: RendererModalParams): string => {
  const { polaris: isPolarisSite = false, marketplace: isMarketplaceSite = false, allowHidingAds } = siteConfig;

  const config = (window.gdpr as Config) || {};
  const isHidingAdsAllowed = config.allowHidingAds || allowHidingAds || false;

  const modalHeaderHTML = createModalHeaderHTML(locale, languagesConfig, isPolarisSite);
  const modalSmokeTestHTML = createSmokeTestHTML(locale, languagesConfig);
  const modalInfoSectionHTML = createModalInfoSectionHTML(
    locale,
    languagesConfig,
    linksSettings,
    isMarketplaceSite,
    isPolarisSite
  );
  const modalSchibstedSectionHTML = createModalSchibstedSectionHTML(locale, languagesConfig, linksReadmore);
  const modalAdvertiserHTML = createModalAdvertiserHTML(locale, languagesConfig);
  const modalButtonsSectionHTML = createModalButtonsSectionHTML(locale, languagesConfig, isHidingAdsAllowed);

  return `
        <div id='gdprAdTransparencyModal' data-testid="gdpr-modal" class="gdpr-modal ${isHidingAdsAllowed ? 'gdpr-modal--hiding-ads' : ''}" role="dialog" aria-modal aria-labelledby="gdpr-modal__label" style="display: none">
            <div class='gdpr-modal__content'>
                <div class='gdpr-modal__content-top'>
                  ${modalHeaderHTML}
                  ${modalSmokeTestHTML}
                  ${modalInfoSectionHTML}
                  ${modalSchibstedSectionHTML}
                  ${modalAdvertiserHTML}
                </div>
                <div class='gdpr-modal__content-bottom'>
                  ${modalButtonsSectionHTML}
                </div>
            </div>
        </div>
    `;
};

const removeOldModalIfNeeded = (): void => {
  const modal = document.querySelector<HTMLElement>('#gdprAdTransparencyModal');

  if (modal) {
    modal.remove();
  }
};

const renderModal = (rendererModalParams: RendererModalParams): void => {
  removeOldModalIfNeeded();

  document.body.insertAdjacentHTML('beforeend', createModalHTML(rendererModalParams));
};

export { renderModal };
