import { getLanguagesConfig, notLoggedInLinks } from '../../config';
import { getElementDimensions } from '../../utils/node';
import { getAdvertiserName, getCreativeId } from '../../features/xandr';
import { getSiteConfig } from '../../config/site-config';
import { getLocale } from '../../utils/language';
import { getPulse2DataCookieUUID } from '../../features/pulse';
import { renderModal } from './renderer';
import './styles.scss';

const createModal = (): Record<string, typeof init> => {
  const locale = getLocale();
  const siteConfig = getSiteConfig();
  const { name: siteName = '', links: linksOverride } = siteConfig;
  const languagesConfig = getLanguagesConfig(siteName);

  const links = {
    notLoggedIn: notLoggedInLinks
  };

  // TODO: Uncomment when pulse tracker is fixed
  // const { sendPulseEvent } = initPulse();

  const setCreativeId = (creativeId: number): void => {
    const creativeIdElement = document.querySelector<HTMLElement>('#gdprAdTransparencyModal__creativeId');

    if (creativeIdElement) {
      creativeIdElement.textContent = String(creativeId);
    }
  };

  const generateLinksReadmore = (): string => {
    return linksOverride?.readmore ?? `${links.notLoggedIn[locale]?.readmore}`;
  };

  const generateLinkSettings = (): string => {
    return (
      linksOverride?.settings ||
      `${links.notLoggedIn[locale]?.settings}`.replace('%%%ENVIRONMENT_ID%%%', getPulse2DataCookieUUID())
    );
  };

  const addOpenModalStyles = (): void => {
    const modalContainer = document.querySelector<HTMLElement>('#gdprAdTransparencyModal');

    if (!modalContainer) {
      return;
    }

    modalContainer.classList.add('gdpr-modal--open');
  };

  const addCloseModalStyles = (): void => {
    const modalContainer = document.querySelector<HTMLElement>('#gdprAdTransparencyModal');

    if (!modalContainer) {
      return;
    }

    modalContainer.classList.remove('gdpr-modal--open');
  };

  const setupSettingsLinks = (): HTMLAnchorElement | undefined => {
    const settingsAnchor = document.querySelector<HTMLAnchorElement>('#gdpr-modal__link-settings');

    if (!settingsAnchor) {
      return;
    }

    settingsAnchor.href = generateLinkSettings();

    return settingsAnchor;
  };

  const setupCreativeId = (target: HTMLElement): number => {
    const targetId = getTargetIdFromClick(target);
    const creativeId = getCreativeId(targetId);

    setCreativeId(creativeId);

    return creativeId;
  };

  const setupTracking = (
    targetId: string,
    creativeId: number
  ): { creativeId: number; invCode: string; targetId: string } => {
    const { invCode } = window.apntag.requests.tags[targetId];

    return { targetId, creativeId, invCode };
  };

  const setupTrackingParams = (
    target: HTMLElement,
    creativeId: number
  ): { creativeId: number; invCode: string; targetId: string } | object => {
    const targetId = getTargetIdFromClick(target);
    const trackingParams = setupTracking(targetId, creativeId) || {};
    const hiddenAd = document.querySelector<HTMLElement>('.gdpr-modal__hide-ads');

    if (hiddenAd) {
      hiddenAd.addEventListener('click', () => {
        // TODO: Uncomment when pulse tracker is fixed
        // sendPulseEvent('ClickedOnHideAd', trackingParams);
        hideModalHandler(target);
      });
    }

    return trackingParams;
  };

  const closeModalHandler = ({ target }): void => {
    const clickedInsideOfModal = !target.classList.contains('gdpr-modal--open');
    const clickedOutsideOfCloseModalButton = !target.classList.contains('gdpr-modal__close-button');

    if (clickedInsideOfModal && clickedOutsideOfCloseModalButton) {
      return;
    }

    addCloseModalStyles();
  };

  const hideModalHandler = (target: HTMLElement): void => {
    const container = document.querySelector<HTMLElement>(`#${target.dataset.target}`);
    const hiddenAdColor = '#DCE0E0';

    if (!container) {
      return;
    }

    const dimensions = getElementDimensions(container);
    const placeholder = document.createElement('div');

    placeholder.style.minWidth = `${dimensions.width}px`;
    placeholder.style.height = `${dimensions.height}px`;
    placeholder.style.backgroundColor = hiddenAdColor;

    container.innerHTML = placeholder.outerHTML;

    addCloseModalStyles();
  };

  const handleClick = (target: HTMLElement): void => {
    const isModalAlreadyOpen = target.classList.contains('gdpr-cogwheel-button');

    if (!isModalAlreadyOpen) {
      return;
    }

    const settingsAnchor = document.querySelector<HTMLAnchorElement>('#gdpr-modal__link-settings');
    const gdprModalLinkReadMore = document.querySelector<HTMLElement>('#gdpr-modal__link-read-more');

    addOpenModalStyles();

    setupSettingsLinks();

    setAdvertiserName(target);

    const creativeId = setupCreativeId(target);
    const trackingParams = setupTrackingParams(target, creativeId);

    // TODO: Uncomment when pulse tracker is fixed
    // sendPulseEvent('ClickedOnCogwheel', trackingParams);

    // TODO: Uncomment when pulse tracker is fixed
    // if (settingsAnchor) {
    //   settingsAnchor.addEventListener('click', () => sendPulseEvent('ClickedOnSettingsLink', trackingParams));
    // }

    // TODO: Uncomment when pulse tracker is fixed
    // if (gdprModalLinkReadMore) {
    //   gdprModalLinkReadMore.addEventListener('click', () => sendPulseEvent('ClickedOnInfoLink', trackingParams));
    // }
  };

  const setupModalEvents = (): void => {
    const modalWrapper = document.querySelector<HTMLElement>('#gdprAdTransparencyModal');
    const modalCloseButton = document.querySelector<HTMLElement>('#gdpr-modal__close-button');

    if (modalWrapper) {
      modalWrapper.addEventListener('click', closeModalHandler);
    }

    if (modalCloseButton) {
      modalCloseButton.addEventListener('click', closeModalHandler);
    }
  };

  const init = (): Record<string, typeof handleClick> => {
    renderModal({
      siteConfig,
      languagesConfig,
      locale,
      linksSettings: generateLinkSettings(),
      linksReadmore: generateLinksReadmore()
    });

    setupModalEvents();

    return {
      handleClick
    };
  };

  return {
    init
  };
};

const getTargetIdFromClick = (target: HTMLElement): string => target.dataset.target;

const getPrebidAdvertiserName = (target: HTMLElement): string | undefined =>
  target.parentElement.dataset.prebidAdvertiserName;

const setAdvertiserName = (target: HTMLElement): void => {
  const advertiserNameIdElement = document.querySelector<HTMLElement>('#gdpr-modal__advertiser-name');

  if (!advertiserNameIdElement) {
    return;
  }

  const targetId = getTargetIdFromClick(target);

  const advertiserName = getPrebidAdvertiserName(target) ?? getAdvertiserName(targetId);
  const advertiserNameParentElement = advertiserNameIdElement.parentElement;

  if (!advertiserName && advertiserNameParentElement) {
    advertiserNameParentElement.style.display = 'none';

    return;
  }

  advertiserNameIdElement.textContent = String(advertiserName);
};

export { createModal };
