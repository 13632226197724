import { type cogwheelType } from 'interface/cogwheel';
import { createCogwheelIcon } from './cogwheel-icon';
import { getLocale } from '../../utils/language';
import './styles.scss';

const ariaLabelWhyLocale = getLocale() === 'SV' ? 'Varför ser du denna annons?' : 'Hvorfor ser du denne annonsen?';

const createCogwheelButton = (id: string, type: cogwheelType, customColor: string): HTMLElement => {
  const button = document.createElement('div');

  button.setAttribute('id', `gdprAdTransparencyCogWheelButton-${id}`);
  button.setAttribute('role', 'button');
  button.setAttribute('aria-label', ariaLabelWhyLocale);
  button.classList.add('gdpr-cogwheel-button');
  button.dataset.target = id;
  // use it only for testing purposes
  button.dataset.testid = 'gdpr-cogwheel-button';

  switch (type) {
    case 'predefinedElement':
      button.classList.add('gdpr-cogwheel-button__predefined-element');
      break;
    default:
      button.classList.add('gdpr-cogwheel-button__friendly-iframe');
      break;
  }

  button.appendChild(createCogwheelIcon(customColor));

  return button;
};

export { createCogwheelButton };
