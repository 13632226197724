const cogWheel =
  '<svg width="15" height="15" xmlns="http://www.w3.org/2000/svg"><path d="M13.8 11.8l-.1-.3-1-1.3.3-.9 1.8-.3.2-.1.1-.2V6.5v-.3h-.3L13 5.8a8 8 0 0 0-.4-1 13.3 13.3 0 0 1 1-1.4l.1-.2c0-.2-.5-.8-1.7-1.9h-.5l-1.3 1-1-.3L9 .3 8.9 0 8.6 0H6.5c-.2 0-.3 0-.4.3L5.8 2l-.9.4-1.3-1-.3-.2-1 .7-.9 1v.6l1 1.3-.3.9-1.8.3-.2.1-.1.2V9h.3l1.8.3c0 .3.2.7.4 1a22.3 22.3 0 0 1-1 1.4l-.1.2c0 .2.5.8 1.7 1.9h.5l1.3-1 1 .3.2 1.8.1.2.3.1h2.1c.2 0 .3 0 .4-.3l.3-1.8.9-.4 1.3 1 .3.2 1-.7.9-1v-.3zM9.7 9.7a3 3 0 0 1-2.1.9 3 3 0 0 1-2.2-1 3 3 0 0 1-.9-2c0-.9.3-1.6 1-2.2a3 3 0 0 1 2-.9 3 3 0 0 1 2.2 1c.6.5.9 1.2.9 2a3 3 0 0 1-1 2.2z" fill="#4a4a4a" fill-rule="evenodd"/></svg>';

const createCogwheelIcon = (customColor: string): HTMLElement => {
  const icon = document.createElement('div');

  icon.innerHTML = customColor ? cogWheel.replace('#4a4a4a', customColor) : cogWheel;
  icon.classList.add('gdpr-cogwheel-button');
  icon.classList.add('gdpr-cogwheel-button__icon');
  icon.classList.add('gdprAdTransparencyCogWheelButton__icon');

  return icon;
};

export { createCogwheelIcon };
