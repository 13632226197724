import { type PbjsQueue, type AdUnitInstance, type Dsa } from '../interface/prebid';

const getPrebidObject = (): Window['pbjs'] => {
  return window.pbjs || {};
};

const getPrebidQueue = (): PbjsQueue => {
  return getPrebidObject().que || [];
};

const initPrebidDsa = (): void => {
  const relevantDigital = window.relevantDigital || {};

  const pbjs = getPrebidObject();
  const pbjsQueue = getPrebidQueue();

  pbjsQueue.push(() =>
    pbjs.onEvent('bidWon', (bid) => {
      const instance: AdUnitInstance | undefined = relevantDigital.getAdUnitInstanceByBid(bid);
      if (instance) {
        const dsa = bid.meta?.dsa as Dsa | undefined;
        if (dsa) {
          const { slot } = instance;
          const target = document.getElementById(slot.getSlotElementId());

          changeCogwheelAdvertiserNameInDataset(target, dsa);
        }
      }
    })
  );
};

const changeCogwheelAdvertiserNameInDataset = (target: HTMLElement, dsa?: Dsa): void => {
  if (!dsa) {
    return;
  }

  target.dataset.prebidAdvertiserName = dsa?.paid ?? dsa?.behalf ?? dsa?.transparency[0]?.domain;
};

export { initPrebidDsa };
