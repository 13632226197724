const getElementDimensions = (element: HTMLElement): { height: number; width: number } => {
  const computedStyle: CSSStyleDeclaration = getComputedStyle(element);
  const computedStyleBefore: CSSStyleDeclaration = getComputedStyle(element, ':before');

  const { paddingTop = '0', paddingBottom = '0', paddingLeft = '0', paddingRight = '0' } = computedStyle;
  const { height = '0' } = computedStyleBefore;

  let { clientHeight: elementHeight, clientWidth: elementWidth } = element;

  elementHeight -= parseFloat(paddingTop) + parseFloat(paddingBottom) + parseFloat(height);
  elementWidth -= parseFloat(paddingLeft) + parseFloat(paddingRight);

  return {
    height: elementHeight,
    width: elementWidth
  };
};

export { getElementDimensions };
