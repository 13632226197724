const getCreativeId = (placementId: string): number | null => {
  try {
    const {
      adResponse: { ads = [{ creative_id: null }] }
    } = window.apntag.requests.tags[placementId];

    return ads.at(-1).creative_id;
  } catch (_error) {
    return null;
  }
};

const getAdvertiserName = (placementId: string): string | null => {
  try {
    const {
      adResponse: { ads = [{ dsa: null }] }
    } = window.apntag.requests.tags[placementId];
    const advertiserName = ads.at(-1).dsa.paid;

    return advertiserName === 'null' ? null : advertiserName;
  } catch (_error) {
    return null;
  }
};

export { getCreativeId, getAdvertiserName };
