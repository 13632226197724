import { getSiteConfig } from '../config/site-config';

const getPulse2DataCookieUUID = (): string | null => {
  /* eslint-disable */
  const cookie =
    decodeURIComponent(
      document.cookie.replace(
        new RegExp(
          '(?:(?:^|.*;)\\s*' +
            encodeURIComponent('_pulse2data').replace(/[\-\.\+\*]/g, '\\$&') +
            '\\s*\\=\\s*([^;]*).*$)|^.*$'
        ),
        '$1'
      )
    ) || null;

  if (cookie) {
    return cookie.split(',')[0];
  }

  return null;

  /* eslint-enable */
};

export { getPulse2DataCookieUUID };

const attachPulseTrackerScript = (): void => {
  const pulseScriptUrl = 'https://sdk.pulse.schibsted.com/pulse-modern.min.js';
  const pulseScript = document.querySelector(`script[src="${pulseScriptUrl}"]`);

  if (pulseScript) {
    return;
  }

  (function (w, d, n, t, s, a, b) {
    w[n] =
      w[n] ||
      function () {
        (window[n].q = window[n].q || []).push(arguments);
      };
    a = d.createElement(t);
    [b] = document.getElementsByTagName(t);
    a.async = 1;
    a.src = s;
    if (b) {
      b.parentNode.insertBefore(a, b);
    }
  })(window, document, 'pulse', 'script', pulseScriptUrl);
};

const initPulse = (): Record<string, typeof sendPulseEvent> => {
  attachPulseTrackerScript();

  window.pulse('init', 'mpt-advertising-solutions', null, null, 'cogwheel');

  const getPulseID = (): string => {
    const { pulseID, name } = getSiteConfig();

    const providerId = `sdrn:schibsted:client:${pulseID || name}`;

    return providerId;
  };

  const sendPulseEvent = (action: string, trackingParams: any): boolean => {
    const sdrn = getPulseID();

    window.pulse('cogwheel.trackPageView', {
      type: 'Engagement',
      engagement: 'UIElement',
      object: {
        id: trackingParams.targetId,
        name: action,
        custom: {
          targetId: trackingParams.targetId,
          creativeId: trackingParams.creativeId,
          invCode: trackingParams.invCode,
          source: window.location.href,
          providerId: sdrn
        }
      }
    });

    return true;
  };

  return { sendPulseEvent };
};

export { initPulse };
