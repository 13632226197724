const validLanguages: string[] = ['SV', 'NO', 'NB', 'FI', 'DK'];
const TLDsMapper = {
  SE: 'SV',
  NO: 'NO',
  FI: 'FI',
  DK: 'DK'
};

const getUrlParam = (search = window.location.search, param: string): string | null => {
  const searchParams = new URLSearchParams(search);

  return searchParams.get(param);
};

const getHTMLLanguage = (): string | false => {
  const htmlLang: string = document.documentElement.lang || '';
  const langInValidFormat: string = htmlLang.split('-')[0].split('_')[0].toUpperCase();
  const isValid: boolean = validLanguages.includes(langInValidFormat);

  return isValid && langInValidFormat;
};

const getMetaTagLanguage = (): string | false => {
  const metaContentLang: Element = document.querySelector("meta[http-equiv='content-language']");
  const metaContentLangAttrid: string = metaContentLang?.getAttribute('content').split('-')[0].toUpperCase();
  const isValid: boolean = validLanguages.includes(metaContentLangAttrid);

  return isValid && metaContentLangAttrid;
};

const getMetaNameLanguage = (): string | false => {
  const langMeta: Element = document.querySelector("meta[name='language']");
  const langMetaAttrib: string = langMeta?.getAttribute('content').split('-')[0].toUpperCase();
  const isValid: boolean = validLanguages.includes(langMetaAttrib);
  return isValid && langMetaAttrib;
};

const getGETLang = (): string | false => {
  if (process.env.NODE_ENV !== 'development') {
    return false;
  }
  const queryParamLang: string = getUrlParam(window.location.search, 'gdprLang') || '';
  const lang: string = queryParamLang.toUpperCase();
  const isValid: boolean = validLanguages.includes(lang);

  return isValid && lang;
};

const getFromConstants = (): string | false => {
  const { constants } = window;
  if (typeof constants !== 'object') {
    return false;
  }

  const { LANGUAGE } = constants;

  if (typeof LANGUAGE !== 'string') {
    return false;
  }

  const lang: string = LANGUAGE.toUpperCase();
  const isValid: boolean = validLanguages.includes(lang);

  return isValid && lang;
};

const getLangByTLD = (): string | false => {
  /*
  TLD: Top-level Domains come at the end of domain names.
   */
  const { hostname } = window.location;

  if (hostname === 'localhost') {
    return 'SV';
  }

  // get the last part of the hostname
  const parts = hostname.split('.');
  const tld = parts.at(-1).toUpperCase();

  const lang = TLDsMapper[tld];

  return lang || 'SV';
};

const getLocale = (): string => {
  const locale =
    getGETLang() ||
    getHTMLLanguage() ||
    getMetaTagLanguage() ||
    getMetaNameLanguage() ||
    getFromConstants() ||
    getLangByTLD() ||
    'SV';

  if (locale === 'NB') {
    return 'NO';
  }

  if (validLanguages.some((lang) => lang === locale)) {
    return locale;
  }

  return 'SV';
};

export { getLocale };
