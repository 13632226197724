const notLoggedInLinks = {
  SV: {
    settings: 'https://privacysettings.schibsted.com/',
    readmore: 'https://info.privacy.schibsted.com/se/nastan-allt-som-finns-att-veta-om-personaliserad-reklam/'
  },
  NO: {
    settings: 'https://privacysettings.schibsted.no/',
    readmore: 'https://info.privacy.schibsted.com/no/nesten-alt-du-trenger-a-vite-om-personalisert-annonsering/'
  },
  SE: {
    settings: 'https://privacysettings.schibsted.no/',
    readmore: 'https://info.privacy.schibsted.com/se/nastan-allt-som-finns-att-veta-om-personaliserad-reklam/'
  },
  DK: {
    settings: 'https://privacysettings.schibsted.dk/',
    readmore: 'https://info.privacy.schibsted.com/dk/naesten-alt-der-er-at-vide-om-personlig-annoncering/'
  },
  FI: {
    settings: 'https://privacysettings.schibsted.fi/',
    readmore:
      'https://info.privacy.schibsted.com/fi/lahes-kaikki-mita-sinun-tarvitsee-tietaa-personoiduista-mainoksista/ '
  }
};

export { notLoggedInLinks };
