import { initPrebidDsa } from './features/prebid-dsa';
import { initCogwheel, setupButton } from './components/cogwheel/decorator';

export const DEFAULT_APNTAG = {
  anq: [],
  onEvent: () => {}
};

const run = (): void => {
  window.apntag = window.apntag || DEFAULT_APNTAG;
  window.apntag.anq = window.apntag.anq || [];
  window.gdpr = window.gdpr || {};
  // cogwheel button doesn't attach to mediated native ads, so we need to attach it manually outside of this package
  window.gdpr.setupButton = setupButton;

  window.apntag.anq.push(() => window.apntag.onEvent('adLoaded', initCogwheel));

  initPrebidDsa();
};

run();
